module.exports = {
  AWS_STACK_DOMAIN: "watt.tv",
  AWS_DEV_EMAIL: "devtesting@daredrop.com",
  awsHostedZoneId: "Z01400293Q8M2AZM4PNJE",
  awsAuthApiId: "9sfp1wmk9e",
  twitchClientId: "ts0c9c61bm0jm3nkdg36xh19ui8vk7",
  paypalClientId:
    "AZ7ruOMika_xOrNIVglKQcPUodUhuoe5ig4BDmZmVeZnWlm8dPCVenyrY7IZfyrT0ezOSDV_EtVwOPIe",
  youtubeApiKeyClient: "AIzaSyDG0J2vit1FXrA3TuN_y89OtySu0tSD4uM",
  secretArns: [
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:PayPal_Test-GPwBVo",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:developmentTwitchSecret-6XOIM5",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:developmentYoutubeOAuth-Noh4mP",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:developmentGoogle-356wvw",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:tiktokOauthSecretDev-mwQwPU",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:paypalApiCertificateDev-bQQPVd",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:cryptoDevelopment-s9iF3G",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:developmentYoutubeOAuth-Noh4mP",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:developmentGoogle-356wvw",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:twitchOverlaySecret-CTe74D",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:twitchPanelSecret-VCZkku",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:sendGridDevelopment-v5Lczm",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:nightbotDevelopment-qahlwb",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:slackBotToken-8zlt0I",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:githubSecrets-8osoap",
    "arn:aws:secretsmanager:us-east-1:918224642237:secret:steamWebAPISecrets-jYATeY",
  ],
};

